<template>
  <div
    class="relative w-full bg-vdv-base touch:md:h-[56px] lg:h-[56px] shrink-0"
  >
    <div
      :class="[
        'flex flex-col items-center max-w-screen-xl grid-cols-12 mx-auto text-white px-md',
        'touch:md:justify-between touch:md:flex-row touch:md:gap-md touch:md:h-full',
        'lg:justify-between lg:flex-row lg:gap-md lg:h-full',
      ]"
    >
      <div
        :class="[
          'flex items-center h-full whitespace-nowrap order-1 py-md',
          'touch:md:w-[400px] touch:md:py-0',
          'lg:w-[400px] lg:py-0',
        ]"
      >
        <BasicLink
          link="/checkout/basket"
          :icon-position="EPosition.LEFT"
          :label="t('checkoutSubView.header.back')"
          target="_self"
          icon="fas fa-chevron-left"
          class="text-white"
        />
      </div>
      <div
        :class="[
          'flex flex-row items-center justify-center order-3 pb-md',
          'touch:md:justify-start touch:md:order-2 touch:md:pb-0',
          'lg:justify-start lg:order-2 lg:pb-0',
        ]"
      >
        <NuxtLink class="flex" to="/">
          <img
            src="~/assets/images/vdv-v-bubble-inverse.svg"
            title="van der ven logo"
            alt="van der ven logo"
            class="h-[36px]"
          />
          <img
            src="~/assets/images/vdv_footer_menschzumensch_claim_white.svg"
            title="van der ven claim"
            alt="van der ven claim"
            class="h-[36px] w-[194px] pl-xs"
          />
        </NuxtLink>
      </div>
      <div
        :class="[
          'flex items-center h-full justify-self-center order-2 pb-sm gap-sm',
          'touch:md:justify-end touch:md:order-3 touch:md:pb-0 touch:md:gap-md touch:md:w-[400px]',
          'lg:justify-end lg:order-3 lg:pb-0 lg:gap-md lg:w-[400px]',
        ]"
      >
        <BasicLink
          link="/agb"
          :label="t('subView.footer.agb')"
          target="_blank"
        />
        <BasicLink
          link="/datenschutz"
          :label="t('subView.footer.privacy')"
          target="_blank"
        />
        <BasicLink
          link="/impressum"
          :label="t('subView.footer.impressum')"
          target="_blank"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { EPosition } from '~/@types/basic-button';
import BasicLink from '@/components/components/core/basicLink/basic-link.vue';

const { t } = useTrans();
</script>
