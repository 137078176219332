<template>
  <div class="relative top-0 left-0 right-0 bg-white shrink-0">
    <div class="max-w-screen-xl mx-auto z-100 print:max-w-[720px]">
      <div class="flex justify-between w-full max-w-screen-xl mx-auto px-md">
        <div
          class="md:absolute flex items-center h-[62px] text-lg text-vdv-base"
        >
          <NuxtLink to="/checkout/basket">
            <div
              class="flex flex-row-reverse gap-1 cursor-pointer md:gap-2 place-items-center"
            >
              <span class="anim__underline-link vdv-anim-link md:inline">
                {{ t('checkoutSubView.header.back') }}
              </span>
              <FaIcon icon-class="fas fa-chevron-left" />
            </div>
          </NuxtLink>
        </div>

        <div class="md:mx-auto md:ml-auto">
          <NuxtLink to="/">
            <img
              src="~/assets/images/van-der-ven-logo.svg"
              title="van der ven logo"
              alt="van der ven logo"
              class="h-[36px] my-3 hidden md:block"
            />
            <img
              src="~/assets/images/vdv-v-bubble.svg"
              title="van der ven logo"
              alt="van der ven logo"
              class="h-[36px] my-3 block md:hidden"
            />
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';

const { t } = useTrans();
</script>
